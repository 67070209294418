import React from 'react';
import { Container } from 'semantic-ui-react';

import Header from '../components/header';
import Layout from '../components/layout';
import Menu from '../components/menu';
import SEO from '../components/seo';

const OtherRadStuff = () => (
  <Layout>
    <SEO title="Other Rad Stuff" />
    <Header />
    <Menu />
    <div className="max-width__449">
      <Container className="container__ket myriad-regular" text textAlign="center">
        We are currently working on even more cool stuff that we are sure you’ll
        appreciate. Because exceptional design evolves around creativity, we will be
        working with amazing artists and shooters alike and incorporating their
        inspirations and dreams into upcoming releases.
        <br />
        <br />
        Stay Tuned!
      </Container>
    </div>
    <div className="min-width__450">
      <Container className="container__ket myriad-regular" text textAlign="justified">
        We are currently working on even more cool stuff that we are sure you’ll
        appreciate. Because exceptional design evolves around creativity, we will be
        working with amazing artists and shooters alike and incorporating their
        inspirations and dreams into upcoming releases.
        <br />
        <br />
        Stay Tuned!
      </Container>
    </div>
  </Layout>
);

export default OtherRadStuff;
