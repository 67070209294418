import React from 'react';
import { Link } from 'gatsby';

import './menu.css';

const Menu = () => {
  return (
    <div className="ket-menu ket-blue-background">
      <Link activeClassName="active" partiallyActive={true} to="/brass-clip">
        BRASS-CLIP
      </Link>
      <Link activeClassName="active" partiallyActive={true} to="/brass-deflector">
        BRASS-DEFLECTOR
      </Link>
    </div>
  );
};

export default Menu;
